# 
# Main script
#
# Automatic compilation
# Have a look at webpack.config.js (coffee-loader)
# ----------------------------------------------------/


# Vendors
`import { tns } from "../../../node_modules/tiny-slider/src/tiny-slider"`
`import "../js/vendors/infiniteslidev2"`




jQuery ($) ->

  # Lite easing extracted from jQuery Easing
  $.easing['jswing'] = $.easing['swing']
  $.extend $.easing,
    easeInOutQuad: (x, t, b, c, d) ->
      if ((t/=d/2) < 1) then c/2*t*t + b else -c/2 * ((--t)*(t-2) - 1) + b
    easeInOutCubic: (x, t, b, c, d) ->
        if ((t/=d/2) < 1) then c/2*t*t*t + b else c/2*((t-=2)*t*t + 2) + b


  App =
    header: $('#js-site-header')
    menu: $('#js-menu-container')
    slider_timer: ''

    init: ->

      # Init everything needed
      App.bind_events()


    # Binds differents events
    bind_events: ->

      # Toggle depending on scrollTop pos
      $(window).on 'scroll', (e) ->
        top_dist = $(window).scrollTop()
        if top_dist > 10
          App.header.addClass 'is--pinned'
        else
          App.header.removeClass 'is--pinned'
      .triggerHandler 'scroll'

      # Toggle submenus
      $('.js-has--submenu').on 'click', (e) ->
        e.preventDefault()
        if $(@).hasClass('is--open')
          $(@).removeClass 'is--open'
        else
          $('.js-has--submenu').removeClass 'is--open'
          $(@).addClass 'is--open'

      # Close submenu on ESC key
      $(document).on 'keydown', (e) ->
        # ESCAPE key
        if e.keyCode = 27 && $('.js-has--submenu.is--open').length
          $('.js-has--submenu.is--open').removeClass 'is--open'

      # Close submenu on click outside of nav-item
      $(document).on 'click', (e) ->
        if !$(e.target).closest('.nav-item').length && $('.js-has--submenu.is--open').length
          $('.js-has--submenu.is--open').removeClass 'is--open'

      # Toggle mobile menu
      $('#js-navbar-toggler').on 'click', (e) ->
        e.preventDefault()
        if !App.menu.hasClass 'is--active'
          $(@).addClass 'is--active'
          $('#js-mobile-overlay-bg').stop(true, true).fadeIn 250, ->
            App.menu.addClass 'is--active'
        else
          $(@).removeClass 'is--active'
          $('#js-mobile-overlay-bg').stop(true, true).fadeOut 250, ->
            App.menu.removeClass 'is--active'

      # Reset menu display on resize
      $(window).on 'resize', () ->
        win_width = $(window).innerWidth()
        if win_width > 1200 && App.menu.hasClass('is--active')
          $('#js-navbar-toggler').triggerHandler 'click'

      # Sliders
      # Home caption slider
      if $('#js-slider-home-caption').length
        slider_home_caption = tns
          container: '#js-slider-home-caption'
          mode: 'gallery'
          items: 1
          nav: false
          controls: false
          swipeAngle: false
          speed: 1500
          mouseDrag: false
          loop: false
          touch: false
          autoplay: false

      # Home slider
      if $('#js-slider-home').length
        slider_home = tns
          container: '#js-slider-home'
          # mode: 'gallery'
          # animateIn: 'slideInUp'
          # animateOut: 'slideOutUp'
          items: 1
          nav: false
          swipeAngle: false
          speed: 750
          mouseDrag: false
          touch: false
          loop: true
          autoplay: true
          autoplayTimeout: 8750
          autoplayButtonOutput: false

        # Update second slider on nav
        slider_home.events.on 'indexChanged', ->
          slider_info = slider_home.getInfo()
          # index_current = slider_info.index
          index_current_displayed = slider_info.displayIndex - 1 # zero-based
          clearTimeout App.slider_timer
          App.slider_timer = setTimeout ->
            slider_home_caption.goTo index_current_displayed
          , 500

      # Members slider
      if $('#js-slider-members').length
        $('#js-slider-members').infiniteslide
          speed: 25,
          direction: 'left'
          pauseonhover: false

      # Enable Map
      Maps.init() if $('#js-map').length


  # Namespace: Manage the Google Maps
  Maps =

    # Map style options
    style_green_light : [{
        "featureType": "landscape"
        "elementType": "all"
        "stylers": [
          { "saturation": -11 }
          { "hue": "#00ff07" }
        ]
      }
      {
        "featureType": "landscape"
        "elementType": "labels"
        "stylers": [
          { "visibility": "on" }
        ]
      }
      {
        "featureType": "poi"
        "elementType": "all"
        "stylers": [
          { "saturation": "-71" }
          { "hue": "#0700ff" }
          { "lightness": "42" }
          { "visibility": "off" }
          { "weight": "1.34" }
        ]
      }
      {
        "featureType": "poi"
        "elementType": "labels"
        "stylers": [
          { "visibility": "off" }
        ]
      }
      {
        "featureType": "road"
        "elementType": "all"
        "stylers": [
          { "hue": "#00bfff" }
          { "saturation": -79 }
        ]
      }
      {
        "featureType": "road.local"
        "elementType": "all"
        "stylers": [
          { "lightness": 30 }
          { "weight": 1.3 }
        ]
      }
      {
        "featureType": "transit"
        "elementType": "all"
        "stylers": [
          { "visibility": "on" }
          { "saturation": -16 }
          { "hue": "#00bfff" }
        ]
      }
      {
        "featureType": "transit.line"
        "elementType": "all"
        "stylers": [
          { "saturation": -72 }
          { "hue": "#00bfff" }
        ]
      }
      {
        "featureType": "water"
        "elementType": "all"
        "stylers": [
          { "saturation": -65 }
          { "hue": "#00e5ff" }
          { "lightness": 8 }
        ]
      }]


    init: ->
      default_latlng =
        lat: 50.8380579
        lng: 4.3939041
      map = new google.maps.Map document.getElementById('js-map'),
        zoom: 16
        center: default_latlng
        styles: Maps.style_green_light
      marker = new google.maps.Marker
        position: default_latlng
        map: map




  # Init
  $ ->
    App.init()